import React, { useState } from 'react'
import styled from 'styled-components'
import { View } from '@components/layout/View/View'
import Link from 'next/link'
import { media } from 'styled-bootstrap-grid'
import { shadows } from 'theme/styles'
import AnimateSlideDown from '../../../../animations/AnimateSlideDown/AnimateSlideDown'
import LogoLink from '../../../../brand/Logo/LogoLink'
import Nav from '../../../../navigation/Nav/Nav'
import NavItem from '../../../../navigation/Nav/NavItem'
import { navComponents, navLinks, programLinks } from '../navLinks'
import { Dropdown } from './Dropdown'

const programLinksArr = Object.values(programLinks)

const mobileNavItems = [navLinks.conversations, navComponents.freeResources, navLinks.about]

const HeaderContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  z-index: ${(props) => props.theme.zIndex.HeaderContainer};
  position: relative;
  height: ${(props) => props.theme.componentHeights.headerMobile}px;
  padding: ${(props) => props.theme.spacing.xs}px ${(props) => props.theme.spacing.md}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  z-index: ${(props) => props.theme.zIndex.mobileMenu + 1};

  ${media.md`
    height: ${(props) => props.theme.componentHeights.headerMobileMd}px;
  `}
`

const MobileMenu = styled.div`
  position: absolute;
  top: ${(props) => props.theme.componentHeights.headerMobile}px;
  height: 100%;
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.mobileMenu};

  nav {
    height: 100%;
  }
`

const MobileNav = styled(Nav)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${(props) => props.theme.colors.white};
  padding-bottom: ${(props) => props.theme.spacing.md}px;
  ${shadows.strong}
`

const MobileMenuContent = styled(View)`
  ${media.md`
    margin-left: ${(props) => props.theme.spacing.lg}px;
  `}
`

const LogoWrapper = styled(View)`
  ${media.md`
    margin: ${(props) => props.theme.spacing.md}px 0;
  `}
`

const HamburgerWrapper = styled(View)``

export const HeaderMobile = ({ user, signOut }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const closeMenu = () => setMenuOpen(false)

  return (
    <>
      <HeaderContainer>
        <LogoWrapper onClick={closeMenu}>
          <LogoLink />
        </LogoWrapper>

        <HamburgerWrapper onClick={() => setMenuOpen(!menuOpen)}>
          <img
            src={menuOpen ? '/static/menu-close.png' : '/static/menu.png'}
            style={{ width: '26px', height: '26px' }}
            alt="Menu"
          />
        </HamburgerWrapper>
      </HeaderContainer>

      <AnimateSlideDown in={menuOpen}>
        <MobileMenu>
          <MobileNav vertical>
            <MobileMenuContent>
              <NavItem>
                <Dropdown label="Programs" options={programLinksArr} onOptionSelect={closeMenu} />
              </NavItem>
              {mobileNavItems.map(({ href, title, key = title, Component }) => (
                <NavItem key={key} onClick={closeMenu}>
                  {Component ? (
                    <Component />
                  ) : (
                    <Link href={href}>
                      <a>{title}</a>
                    </Link>
                  )}
                </NavItem>
              ))}

              {user && (
                <NavItem onClick={closeMenu}>
                  <Link href={navLinks.course.href}>
                    <a>{navLinks.course.title}</a>
                  </Link>
                </NavItem>
              )}

              <NavItem onClick={closeMenu}>
                {user ? (
                  <a onClick={signOut}>Log Out</a>
                ) : (
                  <Link href="/sign-in">
                    <a>Log In</a>
                  </Link>
                )}
              </NavItem>
            </MobileMenuContent>
          </MobileNav>
        </MobileMenu>
      </AnimateSlideDown>
    </>
  )
}
